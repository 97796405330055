import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import queryString from 'query-string';
// import { useNavigate } from "react-router-dom";

import SessionPageFrame from '../components/SessionPageFrame.js';
import PanelHeader from '../components/PanelHeader.js';
import ApiCall from '../components/ApiCall.js';
import themeSettings from '../theme.js';

import constants from '../constants.js';
// import andopen from './andopen.svg';

const theme = createTheme(themeSettings);

export default function SetNewPassword() {
  const qs = queryString.parse(window.location.search);
  const token = qs.token;

  // const [error, setError] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  function handleReset(password){
    setProcessing(true);
    const options = {
      url: `/password-reset`,
      headers: {},
      data: {
        token,
        password
      },
      method: 'post',
      maxRedirects: 1,
      responseType: 'json',
      withCredentials: true
    };
    ApiCall(options, (e, r) => {
      setProcessing(false);
      setSubmitted(true);
    });
  }

  function SubmittedPanel(){
    return (
      <Box style={{
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.light,
        padding: '20px',
        width: `${constants.panelWidth}px`
        }}>
        <Typography component="h1" variant="h4" color={theme.palette.primary.light}>
          All set.
        </Typography>
        <Typography component="h1" variant="subtitle1" style={{ lineHeight: 1.2, margin: 20, opacity: 0.8 }}  color={theme.palette.primary.light}>
          Use your new password to access developer resources.
          <p>Go to <Link color="inherit" href="https://developers.andopen.co/">developers.andopen.co</Link>
          </p>
        </Typography>
      </Box>
    )
  }

  function ResetPanel({ message }){
    const [password, setPassword] = React.useState('');

    return (
      <PanelHeader ribbon={true} message={`Set a Password`} >
        <Box
          noValidate
          sx={{ mt: 1 }}
          style={{
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingTop: '10px',
            paddingBottom: '20px',
            borderRadius: '4px'
          }}>

          <TextField
            margin="normal"
            required
            fullWidth
            label="New Password"
            autoFocus

            type="password"

            id="new-password"
            name="new-password"
            autoComplete="new-password"
            disabled={processing}

            onChange={e => setPassword(e.target.value)}
            />
          <Button
            onClick={() => handleReset(password)}
            fullWidth
            variant="contained"
            disabled={ processing || !password }
            sx={{
              mt: 3, mb: 2,
            }}>
            Set new password
          </Button>
        </Box>

        <Box
          noValidate
          sx={{ mt: 1 }}
          style={{
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingBottom: '20px',
          }}>

          <Grid container direction="column">
            <Grid item>
              <Grid container direction="row">
                <Grid item>
                  <Link href={``} variant="body2" color={theme.palette.primary.dark}>
                    Sign In
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

      </PanelHeader>
    )
  }


  return (
    <SessionPageFrame>
      { submitted
        ? <SubmittedPanel />
        : <ResetPanel />
      }
    </SessionPageFrame>
  );
}