import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { useNavigate } from "react-router-dom";

import ApiCall from '../components/ApiCall.js';
import Message from '../components/Message.js';
import themeSettings from '../theme.js';

const theme = createTheme(themeSettings);

export default function Webhooks() {
  const [userApps, setUserApps] = React.useState(null);
  const [portals, setPortals] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  // const navigate = useNavigate();

  React.useEffect(() => {
    GetUserApps(userApps => {
      setLoading(false);
      if(!userApps){
        setUserApps(null);
      } else {
        setUserApps(userApps);
        let _portals = {}
        Object.keys(userApps).forEach(teamid => {
          Object.keys(userApps[teamid].apps).forEach(appid => {
            if(userApps[teamid].apps[appid].svix_portal_access){
              _portals[`${teamid}/${appid}`] = userApps[teamid].apps[appid];
              _portals[`${teamid}/${appid}`].teamid = teamid
            }
          })
        })
        setPortals(_portals);
      }
    })
  }, []);

  const GetUserApps = (cb) => {
    const options = {
      url: `/apps`,
      headers: {},
      method: 'get',
      maxRedirects: 1,
      responseType: 'json',
      withCredentials: true
    };

    ApiCall(options, (e, r) => {
      cb(r?r:null)
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        disableGutters
        component="main"
        display="flex"
        style={{
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center'
        }}
        >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>

          { loading
            ? <Message text="Loading…"/>
            : <>
              { userApps
                ? <>
                  { (portals && Object.keys(portals).length > 0)
                    ? <Webhooks portals={portals} />
                    : <Message text="Your account has no webhooks configured" />
                  }
                </>
                : <LoginRedirect text="Sign in to view webhooks" />
              }
            </>
          }
        </Box>
      </Container>
    </ThemeProvider>
  );

  function LoginRedirect({ text }){
    console.log('LoginRedirect', text)
    // navigate(`/sessions?callback=webhooks&message=${text}`)
    window.location.replace(`/sessions?callback=webhooks&message=${text}`)
  }

  function Webhook({ portalid, portal }){
    return (
      <iframe
        title={`Webhooks for ${portalid}`}
        allow="clipboard-write"
        style={{
          inset: '0px',
          height: '1000px',
          border: '0px',
          position: 'relative',
          width: '100%'
        }}
        src={ portal?.svix_portal_access?.url }
      ></iframe>
    )
  }

  function Webhooks({ portals }){
    const [currentPortalId, setCurrentPortalId] = React.useState('');

    React.useEffect(() => {
      if(Object.keys(portals).length > 0){
        setPortals(portals)
        setCurrentPortalId(Object.keys(portals)[0])
      }
    }, [portals])

    const GetTabLabel = (portal) => {
      return (
        <>
          <span>App <b>{portal.appid}</b></span>
          &nbsp;(<span>Team {portal.teamid}</span>)
        </>
      )
    }

    return (
      <div style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        flexGrow: 1
        }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 10,
          paddingRight: 30,
          maxHeight: 100
          }}>
          <Typography component="h1" variant="h4" color={theme.palette.primary.dark}>
            Webhook configuration
          </Typography>
          { Object.keys(portals).length > 0
            && <div style={{
              display: 'flex',
              direction: 'row',
              alignItems: 'center'
              }}>
              <Typography
                component="div"
                variant="body2"
                color={theme.palette.primary.dark}
                maxWidth="200px"
                textAlign="left"
                margin="10"
                lineHeight="1.0"
                >
                Your account is associated with multiple API apps:
              </Typography>
              <FormControl>
                {/*<InputLabel id="portal-label">Webhooks for</InputLabel>*/}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentPortalId}
                  label="Webhooks for"
                  variant="standard"
                  onChange={(e) => setCurrentPortalId(e.target.value)}
                >
                { Object.keys(portals).map(portalid => <MenuItem value={portalid} key={portalid} >{GetTabLabel(portals[portalid])}</MenuItem> )}
                </Select>
              </FormControl>
            </div>
          }
        </div>
        <Webhook portalid={currentPortalId} portal={portals[currentPortalId]} />
      </div>
    )
  }
}