import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import queryString from 'query-string';
import { useNavigate } from "react-router-dom";

import { ReactComponent as AOAccount } from '../components/andopen-account.svg';
import SessionPageFrame from '../components/SessionPageFrame.js';
import PanelHeader from '../components/PanelHeader.js';
import ApiCall from '../components/ApiCall.js';
import Message from '../components/Message.js';
import themeSettings from '../theme.js';

import constants from '../constants.js';

const theme = createTheme(themeSettings);

export default function Sessions() {
  const qs = queryString.parse(window.location.search);
  const qsemail = qs.email;
  const callback = qs.callback || null;
  const message = qs.message || null;
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = React.useState(false);
  const [fail, setFail] = React.useState(false);
  const [signInClicked, setSignInClicked] = React.useState(false);
  const [signOutClicked, setSignOutClicked] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(FirstLoad, []);

  function FirstLoad(){
    const qs = queryString.parse(window.location.search);
    const callback = qs.callback || null;
    const action = qs.action || 'sign-in';

    if(action === 'sign-out'){
      return handleLogout(() => {
        if(callback === 'readmeio'){
          window.location.replace(constants.developer_portal_url)
        } else {
          window.location.replace('/sessions')
        }
      })
    }

    GetUser(user => {
      setLoading(false);
      if(!user){
        setLoggedIn(false)
      } else {
        setLoggedIn(true)
      }
    })
  }

  function handleLogin({ email, password }){
    setSignInClicked(true);
    setFail(false);
    const options = {
      url: `/login`,
      headers: {},
      method: 'post',
      maxRedirects: 1,
      responseType: 'json',
      data: { email, password, redirect: callback },
      withCredentials: true
    };
    ApiCall(options, (e, r) => {
      setSignInClicked(false);
      if(r && r.message === 'login_ok'){
        setFail(false);
        setLoggedIn(true);
        if(r?.redirect?.url){
          window.location.replace(r.redirect.url);
        }
      } else {
        setFail(true);
      }
    })
  };

  function handleLogout(cb){
    const options = {
      url: `/logout`,
      headers: {},
      method: 'post',
      maxRedirects: 1,
      responseType: 'json',
      withCredentials: true
    };
    ApiCall(options, (e, r) => {
      setFail(false);
      setLoggedIn(false);
      setSignInClicked(false);
      setSignOutClicked(false);
      cb && cb();
    });
  }

  function GetUser(cb){
    const options = {
      url: `/user`,
      headers: {},
      method: 'get',
      maxRedirects: 1,
      responseType: 'json',
      withCredentials: true
    };

    ApiCall(options, (e, r) => {
      cb(r?r:null)
    })
  }

  return (
    <SessionPageFrame>
      { loading
        ? <Message text="Loading…" />
        : <> { loggedIn
            ? <SessionPanel />
            : <SignInPanel
                handleLogin={handleLogin}
                message={message}
                qsemail={qsemail}
                />
          } </>
      }
    </SessionPageFrame>
  );

  function SessionPanel(){
    return (
      <Box style={{
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.light,
        padding: '20px',
        width: `${constants.panelWidth}px`
        }}>
        <AOAccount fill={theme.palette.primary.light} style = {{ width: '55px', height: '55px', margin: 20 }} />
        <Typography component="h1" variant="h4" color={theme.palette.primary.light}>
          You’re logged in
        </Typography>
        <Typography component="h1" variant="subtitle1" style={{ lineHeight: 1.2, margin: 20, opacity: 0.8 }}  color={theme.palette.primary.light}>
          <p>You are signed in to your developer account on this browser. </p>
          <p>Configure your webhooks, developer settings and view logs at <Link color="inherit" href="https://developers.andopen.co/">developers.andopen.co</Link>
          </p>
        </Typography>
        <Button
          onClick={() => { navigate('/webhooks') }}
          fullWidth
          variant="text"
          sx={{
            mt: 3, mb: 2,
            color: theme.palette.primary.light
          }}>
          View Webhooks
        </Button>
        <Button
          onClick={() => handleLogout()}
          fullWidth
          variant="text"
          disabled={signOutClicked}
          sx={{
            mt: 3, mb: 2,
            color: theme.palette.primary.light
          }}>
          Sign Out
        </Button>
      </Box>
    )
  }

  function SignInPanel({ qsemail, handleLogin, message }){
    const [email, setEmail] = React.useState(null);
    const [password, setPassword] = React.useState(null);

    return (
      <PanelHeader ribbon={true} showLogo={true} message={message} >
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          style={{
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingTop: '10px',
            paddingBottom: '20px',
            borderRadius: '4px'
          }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email Address"
            autoFocus

            defaultValue={qsemail}
            id="email"
            name="email"
            autoComplete="email"
            disabled={signInClicked}

            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"

            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={signInClicked}

            onChange={e => setPassword(e.target.value)}
            />
            { fail &&
              <Typography component="div" variant="subtitle1">
                <p>
                  <b>Your login attempt failed.</b><br/>
                  Check your credentials and try again.
                </p>
              </Typography>
            }
          <Button
            onClick={() => handleLogin({ email, password })}
            fullWidth
            variant="contained"
            disabled={signInClicked || !email || !password}
            sx={{
              mt: 3, mb: 2,
            }}
          >
            Sign In
          </Button>
        </Box>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          style={{
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingBottom: '20px',
          }}>
          <Grid container direction="column">
            <Grid item>
              <Grid container direction="row">
                <Grid item>
                  <Link href={`request-new-password?email=${email}`} variant="body2" color={theme.palette.primary.dark}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container direction="row">
                <Typography component="div" variant="body2">
                  Don't have an account? &nbsp;
                </Typography>
                <Link href={constants.sign_up_url} variant="body2" color={theme.palette.primary.dark}>
                  Sign Up
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </PanelHeader>
    )
  }
}