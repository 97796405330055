import constants from '../constants.js';
import axios from 'axios';

export default function ApiCall(options, cb){
  const axios_options = {
    ...options,
    url: `${constants.handlers_base_url}${options.url}`,
    headers: {
      ...options.headers||{},
      'x-api-key': constants.handlers_apikey
    }
  };

  axios(axios_options)
  .then(response => {
    cb(null, response.data)
  })
  .catch(error => {
    cb(error.response?.data || error, null)
  })
}
