import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Message from '../components/Message.js';
import themeSettings from '../theme.js';

const theme = createTheme(themeSettings);

export default function Sessions() {

  return (
    <ThemeProvider theme={theme}>
      <Container
        disableGutters
        component="main"
        display="flex"
        style={{
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center'
        }}
        >
        <CssBaseline />
        <Box
          style={{
            marginTop: 0,
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
        <Message text="Api Keys, Teams and Apps will be managed here" />
        </Box>
      </Container>
    </ThemeProvider>
  );
}