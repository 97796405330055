import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Message({ text }){
  return (
    <Box
      className="midscreen"
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Typography component="h1" variant="h5">
        {text}
      </Typography>
    </Box>
  )
}
