import * as React from 'react';

import './App.css';
import { Routes, Route } from "react-router-dom";

// Pages
import RequestNewPassword from './pages/RequestNewPassword.js';
import SetNewPassword from './pages/SetNewPassword.js';
import Sessions from './pages/Sessions.js';
import Webhooks from './pages/Webhooks.js';
import ApiKeys from './pages/ApiKeys.js';
import Home from './pages/Home.js';

import { createTheme } from '@mui/material/styles';
import themeSettings from './theme.js'
const theme = createTheme(themeSettings);

const App = () => {
  console.log(`&Open for Makers. \nportal-ui v${process.env.REACT_APP_VERSION}@${process.env.REACT_APP_GIT}.`);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home color={theme.palette.primary.light} />} />
        <Route path="/sessions" element={<Sessions />} />
        <Route path="/apikeys" element={<ApiKeys />} />
        <Route path="/webhooks" element={<Webhooks />} />
        <Route path="/request-new-password" element={<RequestNewPassword />} />
        <Route path="/set-new-password" element={<SetNewPassword />} />
      </Routes>
    </div>
  );
}

export default App;
